body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #383838;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-titleBar {
  background-color: #454545;
  border-radius: 0px 0px 15px 15px;
  padding: 12px;
}

.auto {
  display: block;
  margin:  auto;
}

.rtl {
  margin-left: auto;
}

.barBtnMargin {
  margin-left: 10px;
}

.container {
  background-color: #383838;
  color: antiquewhite;
}

#slider {
  display: block;
  margin: auto;
  width: 80%;
}

.content {
  background-color: #383838;
  color: antiquewhite;
}

.card {
  background-color: #454545;
  color: antiquewhite;
  border-radius: 15px;
  padding: 20px 0px;
}

.cardReadings {
  margin-left: 20px;
}

.advButton {
  margin: 5px;
}

.settingsButton {
  margin: 5px;
}

.settings {
  background-color: #454545;
  color: antiquewhite;
  border-radius: 15px;
  padding: 20px 0px;
}

.prompt {
  margin: auto;
  width: 50%;
  background-color: #454545;
  color: antiquewhite;
  border-radius: 15px;
  padding: 50px;
}

.answer {
  background-color: #454545;
  color: antiquewhite;
  border-radius: 15px;
  margin: 20px;
  padding: 20px;
}

@keyframes right {
  0% {background-color: #454545;}
  50% {background-color: green;}
  100% {background-color: #454545;}
}

@keyframes wrong {
  0% {background-color: #454545;}
  50% {background-color: red;}
  100% {background-color: #454545;}
}

@keyframes null {
  0% {background-color: #454545;}
  100% {background-color: yellow;}
}